.hero {
  z-index: -1;
  height: 100vh;
  overflow: hidden;
  & > * {
    img {
      object-fit: cover;
      height: 100vh;
      width: 100%;
    }
  }
}
