@use '../../node_modules/codyframe/main/scss/config' as *;

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  background: var(--color-bg-dark);
  padding: var(--space-2xs) var(--space-sm);
  border-radius: 0;

  font-size: 1em;
  font-weight: 500;
  color: var(--color-contrast-higher);
  text-decoration: none;
  line-height: 1.2;
  cursor: pointer;
  text-transform: uppercase;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-bg),
                0 0 0 4px alpha(var(--color-contrast-higher), 0.15);
  }

  &:active {
    transform: translateY(2px);
  }
}

/* primary */
.btn--primary {
  background: var(--color-primary);
  color: var(--color-white);

  &:hover {
    background: var(--color-primary-light);
  }

  &:focus-visible {
    box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.15),
                0 1px 2px alpha(var(--color-primary-darker), 0.25),
                0 1px 4px alpha(var(--color-primary-darker), 0.1),
                0 3px 6px -2px alpha(var(--color-primary-darker), 0.25),
                0 0 0 2px var(--color-bg),
                0 0 0 4px var(--color-primary);
  }
}

/* subtle */
.btn--subtle {
  background: var(--color-bg-lighter);
  color: var(--color-contrast-higher);

  &:hover {
    background: var(--color-bg-light);
  }

  &:focus-visible {
    box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.1),
                0 0 0 1px alpha(var(--color-black), 0.02),
                0 0.3px 0.4px alpha(var(--color-black), 0.025),
                0 1px 3px -1px alpha(var(--color-black), 0.2),
                0 3.5px 6px alpha(var(--color-black), 0.12),
                0 0 0 2px var(--color-bg),
                0 0 0 4px var(--color-contrast-high);
  }
}

/* accent */
.btn--accent {
  background: var(--color-accent);
  color: var(--color-white);

  &:hover {
    background: var(--color-accent-light);
  }

  &:focus-visible {
    box-shadow: inset 0 1px 0 alpha(var(--color-white), 0.15),
                0 1px 2px alpha(var(--color-accent-darker), 0.25),
                0 1px 4px alpha(var(--color-accent-darker), 0.1),
                0 3px 6px -2px alpha(var(--color-accent-darker), 0.1),
                0 0 0 2px var(--color-bg),
                0 0 0 4px var(--color-accent);
  }
}

/* contrast */
.btn--contrast {
  background: alpha(var(--color-contrast-higher), 0.95);
  color: var(--color-bg);

  &:hover {
    background: var(--color-contrast-higher);
  }

  &:focus-visible {
    box-shadow: 0 0.1px 0.3px alpha(var(--color-black), 0.06),
                0 1px 2px alpha(var(--color-black), 0.12),
                0 0 0 2px var(--color-bg),
                0 0 0 4px alpha(var(--color-contrast-higher), 0.95);
  }
}

/* primary subtle */
.btn--primary-subtle {
  background: alpha(var(--color-primary), 0.15);
  color: var(--color-primary);

  &:hover {
    background: alpha(var(--color-primary), 0.12);
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px var(--color-bg),
                0 0 0 4px var(--color-primary);
  }
}

/* accent subtle */
.btn--accent-subtle {
  background: alpha(var(--color-accent), 0.15);
  color: var(--color-accent);

  &:hover {
    background: alpha(var(--color-accent), 0.12);
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px var(--color-bg),
                0 0 0 4px var(--color-accent);
  }
}

.btn--skeleton {
  background: transparent;
  color: var(--color-contrast-high);
  border: 2px solid alpha(var(--color-contrast-high), 0.8);

  &:hover {
    color: var(--color-contrast-higher);
    border: 2px solid var(--color-contrast-higher);
  }
}

/* feedback */
.btn--disabled, .btn[disabled], .btn[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
}

/* a button containing (only) an icon */
.btn--icon {
  padding: 0.5rem;
}

// size
.btn--sm {
  font-size: 0.8em;
}

.btn--md {
  font-size: 1.2em;
}

.btn--lg {
  font-size: 1.4em;
  //padding: var(--space-sm) var(--space-md);
}
