.card {
  position: relative;
  flex-shrink: 0;
  min-width: 300px;
}
.card__link:before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 2;
  display: block;
}
